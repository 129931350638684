

import {computed, defineComponent, onMounted, ref} from "vue";
import RequestPartnerNav from '@/components/RequestPartnerNav.vue';
import WhiteBoxMain from '@/components/WhiteBoxMain.vue';
import {getSentRequest, PartnerReviewRequestResource} from '@/api/partnerReviewApi';
import PartnerRequestListItem from '@/components/PartnerRequestListItem.vue';

export default defineComponent( {
  name: 'RequestSentPage',
  components: {PartnerRequestListItem, WhiteBoxMain, RequestPartnerNav},
  setup() {
    const sentRequest = ref<PartnerReviewRequestResource[]>([])
    const wasRequestComplete = ref(false);

    const noDataFromResponse = computed(() => {
      return wasRequestComplete.value === true && sentRequest.value.length === 0;
    })
    onMounted(() => {
      getSentRequest().then(res => {
        sentRequest.value = res;
        wasRequestComplete.value = true;
      })
    })

    return {
      sentRequest,
      noDataFromResponse
    }
  },

});
