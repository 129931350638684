
import {defineComponent, PropType} from "vue";

export default defineComponent({
  name: 'HideEye',
  props: {
    fillColor: {
      type: String,
      default: '#0550D1',
    }
  }
})
