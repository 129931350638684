<template>
  <div class="box"><slot /></div>
</template>
<script>
import {defineComponent} from "vue";

export default defineComponent({
  name: 'WhiteBoxMain'
})
</script>
<style lang="sass" scoped>
.box
  border-radius: 10px
  max-width: 1055px
  min-height: 50vh
  margin: 0 auto 120px
  display: grid
  grid-template-columns: 1fr 5fr
  grid-template-rows: 1fr
  grid-column-gap: 30px
  background: #fff
</style>
