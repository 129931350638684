import { createTextVNode as _createTextVNode, resolveComponent as _resolveComponent, withCtx as _withCtx, createVNode as _createVNode, openBlock as _openBlock, createElementBlock as _createElementBlock } from "vue"

export function render(_ctx: any,_cache: any,$props: any,$setup: any,$data: any,$options: any) {
  const _component_menu_item_navigation = _resolveComponent("menu-item-navigation")!

  return (_openBlock(), _createElementBlock("div", null, [
    _createVNode(_component_menu_item_navigation, { url: "/request-partner" }, {
      default: _withCtx(() => [
        _createTextVNode("Send Request")
      ]),
      _: 1
    }),
    _createVNode(_component_menu_item_navigation, { url: "/review-request" }, {
      default: _withCtx(() => [
        _createTextVNode("Review Requests")
      ]),
      _: 1
    }),
    _createVNode(_component_menu_item_navigation, { url: "/request-approved" }, {
      default: _withCtx(() => [
        _createTextVNode("Approved Requests")
      ]),
      _: 1
    }),
    _createVNode(_component_menu_item_navigation, { url: "/request-sent" }, {
      default: _withCtx(() => [
        _createTextVNode("Sent Requests")
      ]),
      _: 1
    })
  ]))
}